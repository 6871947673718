.modal-content-wrapper {
  width: 492px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #ffffff;
  text-align: center;
  padding: 69px 50px 50px;
  .title-container {
    font-family: 'Prompt-SemiBold', sans-serif;
    font-weight: bold;
    font-size: 20px;
  }
  .message-container {
    font-family: 'Prompt', sans-serif;
    max-width: 373px;
    font-size: 16px;
    line-height: 1.63;
    color: #1f2d39;
    align-self: center;
    margin-top: 9px;
  }
  .modal-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 52px;
    .modal-btn {
      width: 140px;
      height: 50px;
      font-family: 'Prompt-Medium', sans-serif;
      font-weight: 500;
      font-size: 16px;
      &:last-child {
        margin-left: 20px;
      }
      &:only-child {
        margin: 0;
      }
    }
  }
  &.remove-user-modal {
    .title-container {
      font-family: 'Prompt-SemiBold', sans-serif;
      font-weight: 700;
      font-size: 24px;
    }
    .message-container {
      font-family: 'Prompt', sans-serif;
      font-size: 16px;
    }
  }
  &.camera-no-img-modal, // no image modal
  &.no-camera-modal, // no camera modal
  &.default-failure-modal, // 500 modal
  &.set-pass-success-modal {
    // set password succeed modal
    padding: 69px 98px 50px;
    .title-container {
      font-family: 'Prompt', sans-serif;
      font-weight: bold;
      font-size: 21px;
    }
    .message-container {
      font-family: 'Prompt', sans-serif;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.63;
      white-space: pre;
    }
  }
  &.no-account-modal {
    width: 492px;
    .title-container {
      font-family: 'Prompt', sans-serif;
      font-weight: bold;
      font-size: 21px;
    }
    .message-container {
      font-family: 'Prompt', sans-serif;
      font-weight: normal;
      font-size: 18px;
    }
  }
}

.delete-apikey-modal {
  .title-container {
    font-family: 'Prompt-SemiBold', sans-serif;
    font-size: 24px;
  }
  .message-container {
    font-family: 'Prompt', sans-serif;
    font-size: 16px;
  }
  .modal-btn-wrapper {
    .modal-btn {
      font-family: 'Prompt-Medium', sans-serif;
      font-size: 16px;
    }
  }
}
