.application-modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2;
  .application-modal-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    .modal-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      .content {
        position: relative;
        .modal-close-btn {
          position: absolute;
          top: 30px;
          right: 30px;
          width: 16px;
          height: 16px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
          }
        }
      }
    }
  }
}
